import React, { useEffect, useState } from "react";
import { Grid, Chip } from "@mui/material";
import styles from "./styles.module.css";
import Loader from "../../utils/Loading";
import { ROUTES, STORAGE_KEYS } from "../../../const";
import { useNavigate } from "react-router-dom";
import TerminalService from "../../../services/TerminalService";
import TerminalForceAttachDialog from "../../popups/TerminalForceAttachDialog";

function Counter() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [terminals, setTerminals] = useState(null);
  const [forceAttachTerminal, setForceAttachTerminal] = useState(null);

  const getTerminals = async () => {
    setIsLoading(true);
    try {
      const resp = await TerminalService.getTerminals({ type: "pos" });
      const data = resp.filter((item) => item.type === "pos");
      setTerminals(data);
    } catch (error) {
      console.log("Error: ", error);
    }
    setIsLoading(false);
  };

  const attachTerminalHandler = async (terminal, force = false) => {
    if (terminal?.mac_id && force !== true) {
      setForceAttachTerminal(terminal);
      return;
    }
    setIsLoading(true);
    try {
      let data = { mac_id: '00034HR24KF23'};
      if (force) {
        data = { ...data, force: true };
      }
      const resp = await TerminalService.attachTerminal(terminal?.id, data);
      localStorage.setItem(STORAGE_KEYS.TERMINAL, terminal?.id);
      localStorage.setItem(STORAGE_KEYS.TERMINAL_AUTH_TOKEN, resp?.auth_token )
      localStorage.setItem(STORAGE_KEYS.SALESPERSON_LOGIN, resp?.salesperson_login || true);
      setForceAttachTerminal(null)
      navigate(ROUTES.BILL_NUMBER);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEYS.TERMINAL)) {
      navigate(ROUTES.BILL_NUMBER);
    }
    getTerminals();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Loader isOpen={isLoading} />
      <Grid container rowSpacing={6} className={styles.textArea}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
              <span className={styles.head2}>
                SELECT <span className={styles.head1}>TERMINAL</span>
              </span>
            </Grid>
            <Grid item xs={12} className={styles.text}>
              <span>Please select your terminal to continue.</span>
            </Grid>
          </Grid>
        </Grid>
        {!isLoading && (
          <Grid item xs={12}>
            <Grid container className={styles.selectWrapper}>
              {(terminals || []).map((terminal) => (
                <Grid
                  key={terminal?.id}
                  item
                  xs={12}
                  textAlign={"center"}
                  onClick={() => attachTerminalHandler(terminal)}
                  className={styles.selectContainer}
                >
                  <span>{terminal?.name}</span>
                  {terminal?.mac_id ? (
                    <Chip label=" attached " size="small" color="primary" />
                  ) : (
                    <Chip label="can attach" size="small" color="success" />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      {forceAttachTerminal && (
        <TerminalForceAttachDialog
          handleClose={() => {
            setForceAttachTerminal(null);
          }}
          forceAttachTerminal={() =>
            attachTerminalHandler(forceAttachTerminal, true)
          }
        />
      )}
    </>
  );
}

export default Counter;
